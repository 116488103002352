import styled from "styled-components";

export const Content = styled.div`
    padding: 15px;

    ${(props) => (props.flex ? `display: flex;flex: 1;` : ``)}
`;

export const Alert = styled.div`
    color: #fff;
    text-shadow: none;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 0px;

    ${(props) =>
        props.danger
            ? `
        border-left: 5px solid #953b39;
        background-color: #c26565;
    `
            : ``}

    ${(props) =>
        props.success
            ? `
        border-left: 5px solid #8ac38b;
        background-color: #dff0d8;
        color: #356635;
    `
            : ``}

    ${(props) =>
        props.warning
            ? `
        border-left: 5px solid #e0b66c;
        background-color: #fcf8e3;
        color: #826430;
    `
            : ``}
`;
