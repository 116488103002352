export const REDUX_SET_VALUE = "REDUX_SET_VALUE";
export const REDUX_LOGOUT = "REDUX_LOGOUT";

export function reduxSetValue(setting, value) {
    return {
        type: REDUX_SET_VALUE,
        payload: {
            setting: setting,
            value: value,
        },
    };
}

export function reduxLogout() {
    return { type: REDUX_LOGOUT };
}
