import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/images/logo.jpg";
import { Button } from "../components";

const Wrapper = styled.div`
    background: #fff;
    display: flex;
    height: 100vh;
    overflow: auto;
    justify-content: center;
    align-items: center;
    outline: none;
`;

const Container = styled.div`
    max-width: 500px;
`;

export const Error404 = () => {
    const navigate = useNavigate();

    const back = () => {
        navigate("/");
    };

    return (
        <Wrapper>
            <Container>
                <div style={{ textAlign: "center" }} className="mb-4">
                    <img src={logo} alt="ZiFF Logo" style={{ margin: "auto" }} />
                </div>
                Die aufgerufene Seite wurde nicht gefunden ...
                <div className="mt-1">
                    <Button onClick={back}>Zur Startseite</Button>
                </div>
            </Container>
        </Wrapper>
    );
};
