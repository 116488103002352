import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

    html, body {
        padding: 0px;
        margin: 0px;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
    }

    .m-0 {
        margin: 0px !important;
    }
    .ml-0 {
        margin-left: 0px !important;
    }
    .ml-1 {
        margin-left: 0.25rem !important;
    }
    .ml-2 {
        margin-left: 0.5rem !important;
    }
    .ml-3 {
        margin-left: 1rem !important;
    }
    .ml-4 {
        margin-left: 1.5rem !important;
    }
    .ml-5 {
        margin-left: 3rem !important;
    }
    .mr-0 {
        margin-right: 0px !important;
    }
    .mr-1 {
        margin-right: 0.25rem !important;
    }
    .mr-2 {
        margin-right: 0.5rem !important;
    }
    .mr-3 {
        margin-right: 1rem !important;
    }
    .mr-4 {
        margin-right: 1.5rem !important;
    }
    .mr-5 {
        margin-right: 3rem !important;
    }
    .mt-0 {
        margin-top: 0px !important;
    }
    .mt-1 {
        margin-top: 0.25rem !important;
    }
    .mt-2 {
        margin-top: 0.5rem !important;
    }
    .mt-3 {
        margin-top: 1rem !important;
    }
    .mt-4 {
        margin-top: 1.5rem !important;
    }
    .mt-5 {
        margin-top: 3rem !important;
    }
    .mb-0 {
        margin-bottom: 0px !important;
    }
    .mb-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-3 {
        margin-bottom: 1rem !important;
    }
    .mb-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-5 {
        margin-bottom: 3rem !important;
    }
    .p-0 {
        padding: 0px !important;
    }
    .pl-0 {
        padding-left: 0px !important;
    }
    .pl-1 {
        padding-left: 0.25rem !important;
    }
    .pl-2 {
        padding-left: 0.5rem !important;
    }
    .pl-3 {
        padding-left: 1rem !important;
    }
    .pl-4 {
        padding-left: 1.5rem !important;
    }
    .pl-5 {
        padding-left: 3rem !important;
    }
    .pr-0 {
        padding-right: 0px !important;
    }
    .pr-1 {
        padding-right: 0.25rem !important;
    }
    .pr-2 {
        padding-right: 0.5rem !important;
    }
    .pr-3 {
        padding-right: 1rem !important;
    }
    .pr-4 {
        padding-right: 1.5rem !important;
    }
    .pr-5 {
        padding-right: 3rem !important;
    }
    .pt-0 {
        padding-top: 0px !important;
    }
    .pt-1 {
        padding-top: 0.25rem !important;
    }
    .pt-2 {
        padding-top: 0.5rem !important;
    }
    .pt-3 {
        padding-top: 1rem !important;
    }
    .pt-4 {
        padding-top: 1.5rem !important;
    }
    .pt-5 {
        padding-top: 3rem !important;
    }
    .pb-0 {
        padding-bottom: 0px !important;
    }
    .pb-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-3 {
        padding-bottom: 1rem !important;
    }
    .pb-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-5 {
        padding-bottom: 3rem !important;
    }
`;
