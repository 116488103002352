import { createStore, combineReducers, compose } from "redux";
import appReducer from "./app/reducer";

const rootReducer = combineReducers({
    app: appReducer,
});

let rootEnhancer = compose();
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && process.env.NODE_ENV === "development") {
    rootEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: "ZiFF-Cashbox-App",
    })(rootEnhancer);
}

const initialStore = {};
const store = createStore(rootReducer, initialStore, rootEnhancer);
export { store };
