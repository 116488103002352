import { BrowserRouter, Routes, Route } from "react-router-dom";
import moment from "moment";
import momentDe from "moment/locale/de";
import { Provider } from "react-redux";
import { store } from "./redux";
import { Layout, GlobalStyles } from "./components";
import { Error404 } from "./screens/Error404";
import { Login } from "./screens/Login";
import { Start } from "./screens/Start";

// moment
moment.updateLocale("de", momentDe);

function App() {
    return (
        <Provider store={store}>
            <GlobalStyles />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route
                        path="/cashbox/*"
                        element={
                            <Layout>
                                <Routes>
                                    <Route path="/" element={<Start />} />
                                </Routes>
                            </Layout>
                        }
                    />
                    <Route path="*" element={<Error404 />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
