import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/images/logo.jpg";
import { Alert, Button, Input, Label } from "../components";
import { ApiHelper, AuthHelper } from "../helpers";

const Wrapper = styled.div`
    background: #fff;
    display: flex;
    height: 100vh;
    overflow: auto;
    justify-content: center;
    align-items: center;
    outline: none;
`;

const Container = styled.div`
    max-width: 500px;
`;

export const Login = () => {
    const navigate = useNavigate();
    const auth = useSelector((state) => state.app.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const login = (e) => {
        e.preventDefault();
        setAlert(null);
        setIsLoading(true);

        ApiHelper.request("/bistro-app/auth/login", {
            email: email,
            password: password,
        })
            .then((response) => {
                if (
                    response.status === "success" &&
                    response.data &&
                    response.data.token &&
                    response.data.token.trim() !== "" &&
                    response.data.user &&
                    response.data.user.id &&
                    response.data.user.username &&
                    response.data.user.email
                ) {
                    AuthHelper.login(response.data.token, { ...response.data.user });
                    navigate("/cashbox");
                } else {
                    setAlert(
                        response && response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Einloggen aufgetreten."
                    );
                    setIsLoading(false);
                }
            })
            .catch((e) => {
                console.error(e);
                setAlert(
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Einloggen aufgetreten: " + JSON.stringify(e)
                );
                setIsLoading(false);
            });
    };

    if (auth && auth.id && auth.username && auth.email) {
        return <Navigate to="/cashbox" />;
    }

    console.log("start");
    return (
        <Wrapper>
            <Container>
                <div style={{ textAlign: "center" }} className="mb-4">
                    <img src={logo} alt="ZiFF Logo" style={{ margin: "auto" }} />
                </div>

                <form onSubmit={login}>
                    {alert ? <Alert danger>{alert}</Alert> : null}

                    <Label>E-Mail Adresse</Label>
                    <Input type="email" className="mb-2" disabled={isLoading} onChange={(e) => setEmail(e.target.value)} value={email} />

                    <Label>Passwort</Label>
                    <Input
                        type="password"
                        className="mb-2"
                        disabled={isLoading}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />

                    <Button block disabled={isLoading}>
                        Einloggen
                    </Button>
                </form>
            </Container>
        </Wrapper>
    );
};
