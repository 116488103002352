import { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import moment from "moment";
import { faSpinner, faTrash, faPlusSquare, faMinusSquare, faEdit, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, ArticlesSelector, Button, ContactSelector, Content } from "../components";
import { ApiHelper, NumericHelper } from "../helpers";

const InfoBox = styled.div`
    border: 1px solid #eee;
    border-radius: 5px;
    margin-bottom: 15px;
`;

const TableWrapper = styled.table`
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;

    thead tr th {
        text-align: left;
        font-weight: 600;
        border-bottom: 2px solid #eee;
        padding: 0px;
        padding-bottom: 3px;
    }

    tbody tr td {
        padding: 5px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    tbody tr:last-child td {
        border-bottom: none;
    }
`;

const Input = styled.input`
    padding: 8px 15px;
    width: auto;
    box-sizing: border-box;
    border: 1px solid #555;
    border-radius: 3px;
`;

const defaultState = {
    contact: null,
    articles: [],
    create_payment: true,
};

const preparedContactData = {
    city: "Essen",
    email: "",
    firstname: null,
    id: 26962,
    lastname: "Diverse",
    street: "Katernberger Str. 107",
    zip: "45327",
};

export const Start = (props) => {
    const [customerObject, setCustomerObject] = useState(null);
    const [articleObjects, setArticleObjects] = useState({});
    const [data, setData] = useState({ ...defaultState });
    const [editingBrutto, setEditingBrutto] = useState(null);
    const [editingBruttoValue, setEditingBruttoValue] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [hasError, setHasError] = useState(null);
    const [hasSuccess, setHasSuccess] = useState(null);

    // const [hasSuccess, setHasSuccess] = useState({
    //     date: moment().calendar(),
    //     number: "RGNUMBER",
    //     sumup_payment_url: "https://google.de/",
    //     amount: 12.5,
    // });

    const onCustomerChange = (newCustomer) => {
        if (newCustomer && newCustomer.id) {
            setCustomerObject({ ...newCustomer });
            setData({
                ...data,
                customer: newCustomer.id,
            });
        } else {
            setCustomerObject(null);
            setData({
                ...data,
                customer: null,
            });
        }
    };

    const setPreparedContact = () => {
        setCustomerObject({ ...preparedContactData });
        setData({
            ...data,
            customer: preparedContactData.id,
        });
    };

    const onCreatePaymentChange = (e) => {
        const index = parseInt(e.target.getAttribute("data-index"));
        const newArticles = [...data.articles];
        newArticles[index].quantity--;

        setData({
            ...data,
            articles: newArticles,
        });
        setData({
            ...data,
            create_payment: e.target.checked,
        });
    };

    const addQuantity = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        const newArticles = [...data.articles];
        newArticles[index].quantity++;

        setData({
            ...data,
            articles: newArticles,
        });
    };

    const removeQuantity = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        const newArticles = [...data.articles];
        newArticles[index].quantity--;

        setData({
            ...data,
            articles: newArticles,
        });
    };

    const onArticleChange = (e) => {
        const index = parseInt(e.target.getAttribute("data-index"));

        const newArticles = [...data.articles];
        newArticles[index][e.target.name] = e.target.value;

        setData({
            ...data,
            articles: newArticles,
        });
    };

    const onArticleSelect = (selection) => {
        const newArticles = data.articles && data.articles.length > 0 ? [...data.articles] : [];
        const newArticleObjects = { ...articleObjects };
        for (let articleKey in selection) {
            if (!selection[articleKey] || !selection[articleKey].count || parseInt(selection[articleKey].count) < 1) {
                continue;
            }

            let add = true;
            for (let i in newArticles) {
                if (parseInt(newArticles[i].id) === parseInt(articleKey)) {
                    newArticles[i].quantity += selection[articleKey].count;
                    add = false;
                }
            }

            if (add) {
                newArticles.push({
                    id: selection[articleKey].id,
                    price: selection[articleKey].price,
                    quantity: parseInt(selection[articleKey].count),
                });
            }
            newArticleObjects[parseInt(selection[articleKey].id)] = {
                id: selection[articleKey].id,
                name: selection[articleKey].name,
                price: selection[articleKey].price,
                tax: selection[articleKey].tax,
                color: selection[articleKey].color,
            };
        }

        setArticleObjects({ ...newArticleObjects });
        setData({
            ...data,
            articles: newArticles,
        });
    };

    const onArticleRemove = (e) => {
        const index = parseInt(e.currentTarget.getAttribute("data-index"));
        if (!window.confirm("Soll der ausgwählte Artikel entfernt werden?")) {
            return;
        }

        const newArticles = [...data.articles];
        newArticles.splice(index, 1);
        setData({
            ...data,
            articles: newArticles,
        });
    };

    const startEditBrutto = (e) => {
        const index = e.currentTarget.getAttribute("data-index");
        const article = articleObjects[parseInt(data.articles[index].id)];
        if (!article) {
            window.alert("Der ausgewählte Artikel wurde nicht gefunden. Brutto-Preis nicht verändert ...");
            return;
        }

        setEditingBruttoValue(
            article.tax ? data.articles[index].price + data.articles[index].price * article.tax : data.articles[index].price
        );
        setEditingBrutto(index);
    };

    const cancelEditingBrutto = () => {
        setEditingBrutto(null);
        setEditingBruttoValue(null);
    };

    const onEditingBruttoChange = (e) => {
        setEditingBruttoValue(e.target.value);
    };

    const applyEditingBrutto = () => {
        const article = articleObjects[parseInt(data.articles[editingBrutto].id)];
        if (!article) {
            window.alert("Der ausgewählte Artikel wurde nicht gefunden. Brutto-Preis nicht verändert ...");
            return;
        }

        const temp = article.tax ? parseFloat(editingBruttoValue) / (1 + article.tax) : parseFloat(editingBruttoValue);

        const newArticles = [...data.articles];
        newArticles[parseInt(editingBrutto)].price = parseFloat(temp);

        setData({
            ...data,
            articles: newArticles,
        });
        setEditingBrutto(null);
        setEditingBruttoValue(null);
    };

    const payWithSumup = () => {
        window.open(hasSuccess.sumup_payment_url);
        setHasSuccess({
            ...hasSuccess,
            clicked: true,
        });
    };

    const save = () => {
        if (!data.customer) {
            window.alert("Es muss ein Kunde ausgewählt werden!");
            return;
        }

        if (!data.articles || data.articles.length < 1) {
            window.alert("Es muss mindestens ein Artikel zur Rechnung hinzugefügt werden!");
            return;
        }

        setHasSuccess(null);
        setHasError(null);
        setIsSaving(true);
        ApiHelper.request("/bistro-app/bistro-invoices/create", { ...data })
            .then((response) => {
                if (response.status === "success") {
                    // setHasSuccess(
                    //     <span>
                    //         <b>{moment().calendar()}:</b> Die Rechnung <b>#{response.data.invoice_number}</b> wurde erfolgreich angelegt.
                    //         {response.data && response.data.sumup_payment_url ? (
                    //             <div>
                    //                 <a href={response.data.sumup_payment_url}>Mit SumUp bezahlen</a>
                    //             </div>
                    //         ) : null}
                    //     </span>
                    // );
                    setHasSuccess({
                        date: moment().calendar(),
                        number: response.data.invoice_number,
                        sumup_payment_url: response.data && response.data.sumup_payment_url ? response.data.sumup_payment_url : null,
                        amount: response.data.amount,
                    });
                    setData({ ...defaultState });
                } else {
                    setHasError(
                        response && response.status === "error" && response.message && response.message.trim() !== ""
                            ? response.message
                            : "Es ist ein unerwarteter Fehler beim Speichern der Rechnung aufgetreten."
                    );
                }
                setIsSaving(false);
            })
            .catch((e) => {
                console.error(e);
                setHasSuccess(null);
                setHasError(
                    e && e.data && e.data.status === "error" && e.data.message && e.data.message.trim() !== ""
                        ? e.data.message
                        : "Es ist ein unerwarteter Fehler beim Speichern der Rechnung aufgetreten."
                );
                setIsSaving(false);
            });
    };

    useEffect(() => {
        setData({ ...defaultState });
    }, []);

    if (isSaving) {
        return (
            <Content flex style={{ justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <div>
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                </div>
                <div>Die Rechnung wird erstellt ...</div>
            </Content>
        );
    }

    if (hasSuccess) {
        return (
            <Content flex style={{ alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <div style={{ fontSize: 46, color: "#31bd31" }}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                </div>
                <div>
                    {hasSuccess.date}: Die Rechnung wurde erfolgreich im CRM angelegt
                    <br />
                    <br />
                    <span style={{ width: 140, display: "inline-block", fontWeight: "bold" }}>Rechnungsnummer:</span> {hasSuccess.number}
                    <br />
                    <span style={{ width: 140, display: "inline-block", fontWeight: "bold" }}>Rechnungsbetrag:</span>{" "}
                    {hasSuccess.amount ? NumericHelper.numberFormat(hasSuccess.amount) + " €" : "-"}
                    <br />
                    <br />
                    <Button type="button" onClick={payWithSumup}>
                        Mit Sumup bezahlen
                        {hasSuccess.clicked ? <FontAwesomeIcon icon={faCheckCircle} className="ml-2" /> : null}
                    </Button>
                    <Button type="button" light className="ml-2" onClick={() => setHasSuccess(null)}>
                        Nächste Rechnung generieren
                    </Button>
                </div>
            </Content>
        );
    }

    let fullPrice = 0;
    return (
        <Content flex>
            <div style={{ flex: 1, overflow: "auto", paddingRight: 15 }}>
                {hasError ? <Alert danger>{hasError}</Alert> : null}

                {editingBrutto ? (
                    <Modal
                        isOpen={true}
                        onRequestClose={cancelEditingBrutto}
                        style={{
                            content: {
                                top: "50%",
                                left: "50%",
                                right: "auto",
                                bottom: "auto",
                                marginRight: "-50%",
                                transform: "translate(-50%, -50%)",
                                padding: 0,
                                boxSizing: "border-box",
                            },
                        }}
                    >
                        <div style={{ maxWidth: 400, width: "100%", padding: 15, overflow: "auto", boxSizing: "border-box" }}>
                            <label>Brutto bearbeiten:</label>
                            <Input
                                type="number"
                                value={editingBruttoValue}
                                onChange={onEditingBruttoChange}
                                style={{ width: "100%", display: "block", margin: 0, marginTop: 5 }}
                            />

                            <div style={{ textAlign: "right", paddingTop: 3 }}>
                                <Button type="button" success onClick={applyEditingBrutto}>
                                    Übernehmen
                                </Button>
                            </div>
                        </div>
                    </Modal>
                ) : null}

                <InfoBox>
                    <Content>
                        <h3 className="mt-0">Artikel</h3>
                        {!data || !data.articles || data.articles.length < 1 ? (
                            "Es wurden noch keine Artikel hinzugefügt"
                        ) : (
                            <TableWrapper>
                                <thead>
                                    <tr>
                                        <th>Artikelbezeichnung</th>
                                        <th style={{ textAlign: "right", width: 80 }}>MwSt.</th>
                                        <th style={{ textAlign: "right", width: 120 }}>Menge</th>
                                        <th style={{ textAlign: "right", width: 120 }}>EP Netto</th>
                                        <th style={{ textAlign: "right", width: 120 }}>EP Brutto</th>
                                        <th style={{ textAlign: "right", width: 120 }}>GP Brutto</th>
                                        <th style={{ width: 50 }} />
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.articles.map((article, i) => {
                                        const articleObject = articleObjects[article.id];
                                        if (!articleObject) {
                                            return (
                                                <tr key={i}>
                                                    <td colSpan={5} style={{ color: "red" }}>
                                                        Artikel konnte nicht geladen werden ...
                                                    </td>
                                                </tr>
                                            );
                                        }

                                        const singlePriceNetto = article.price;
                                        const singlePriceBrutto = parseFloat(
                                            parseFloat(article.price) +
                                                (articleObject.tax ? articleObject.tax * parseFloat(article.price) : 0)
                                        ).toFixed(2);
                                        const fullPriceBrutto = singlePriceBrutto * article.quantity;
                                        fullPrice += fullPriceBrutto;

                                        return (
                                            <tr key={i}>
                                                <td>{articleObject.name}</td>
                                                <td style={{ textAlign: "right" }}>{articleObject.tax}</td>
                                                <td style={{ textAlign: "right", userSelect: "none" }}>
                                                    {article.quantity > 1 ? (
                                                        <FontAwesomeIcon
                                                            icon={faMinusSquare}
                                                            style={{ marginRight: 5, opacity: 0.4, fontSize: 16, cursor: "pointer" }}
                                                            onClick={removeQuantity}
                                                            data-index={i}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faMinusSquare}
                                                            style={{ marginRight: 5, opacity: 0.15, fontSize: 16 }}
                                                            data-index={i}
                                                        />
                                                    )}
                                                    {article.quantity}
                                                    <FontAwesomeIcon
                                                        icon={faPlusSquare}
                                                        style={{ marginLeft: 5, opacity: 0.4, fontSize: 16, cursor: "pointer" }}
                                                        onClick={addQuantity}
                                                        data-index={i}
                                                    />
                                                </td>
                                                <td style={{ textAlign: "right", paddingLeft: 30 }}>
                                                    <Input
                                                        type="number"
                                                        value={singlePriceNetto}
                                                        onChange={onArticleChange}
                                                        data-index={i}
                                                        name="price"
                                                        style={{ textAlign: "right" }}
                                                    />
                                                </td>
                                                <td style={{ textAlign: "right", paddingLeft: 5 }}>
                                                    {NumericHelper.numberFormat(singlePriceBrutto)}
                                                    <Button
                                                        type="button"
                                                        light
                                                        style={{ marginLeft: 5, padding: 3 }}
                                                        onClick={startEditBrutto}
                                                        data-index={i}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </td>
                                                <td style={{ textAlign: "right", paddingLeft: 5 }}>
                                                    {NumericHelper.numberFormat(fullPriceBrutto)}
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    <Button type="button" danger data-index={i} onClick={onArticleRemove}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </TableWrapper>
                        )}
                    </Content>
                </InfoBox>
                <div style={{ float: "right" }}>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={data.create_payment}
                                onChange={onCreatePaymentChange}
                                style={{ marginRight: 3 }}
                            />
                            SumUp-Zahlung beim Anlegen der Rechnung erstellen (Rechnung als bezahlt markieren)
                        </label>
                    </div>
                    <div style={{ textAlign: "right", marginTop: 5 }}>
                        <Button success onClick={save}>
                            Rechnung erstellen
                        </Button>
                    </div>
                </div>
                <ArticlesSelector label="Artikel hinzufügen" onChange={onArticleSelect} />
            </div>

            <div style={{ width: 300 }}>
                <InfoBox>
                    <Content>
                        <h3 className="mt-0 mb-0">Kunde auswählen</h3>
                        {!data || !data.customer ? (
                            <div style={{ display: "flex" }}>
                                <ContactSelector label="Kunde auswählen" onChange={onCustomerChange} style={{ flex: 1 }} />
                                <Button light style={{ flex: 1, marginLeft: 5 }} onClick={setPreparedContact}>
                                    Kunde: {preparedContactData.lastname}{" "}
                                    {preparedContactData.firstname ? ", " + preparedContactData.firstname : ""}
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <div style={{ float: "right", fontWeight: "bold" }}>#{customerObject.id}</div>
                                {customerObject.lastname}, {customerObject.firstname}
                                <br />
                                {customerObject.street}
                                <br />
                                {customerObject.zip} {customerObject.city}
                                <br />
                                <div className="mt-1" style={{ display: "flex" }}>
                                    <ContactSelector
                                        label="Kunde wechseln"
                                        onChange={onCustomerChange}
                                        style={{ flex: 1, marginLeft: 5 }}
                                    />
                                    <Button light style={{ flex: 1, marginLeft: 5 }} onClick={setPreparedContact}>
                                        Kunde: {preparedContactData.lastname}{" "}
                                        {preparedContactData.firstname ? ", " + preparedContactData.firstname : ""}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Content>
                </InfoBox>
                <InfoBox>
                    <Content>
                        <h3 className="mt-0 mb-1">Preis:</h3>
                        <div style={{ fontSize: 36, fontWeight: "bold" }}>{NumericHelper.numberFormat(fullPrice)} €</div>
                    </Content>
                </InfoBox>
            </div>
        </Content>
    );
};
