import axios from "axios";
import { AuthHelper } from "./AuthHelper";

class ApiHelperWrapper {
    getAxiosHeaders() {
        return {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            },
        };
    }

    request(path, data, method, config) {
        if (!config) {
            config = {};
        }

        if (!data) {
            data = {};
        }

        if (this._env) {
            data._env = this._env;
        }

        if (process.env.REACT_APP_TYPE) {
            data._type = process.env.REACT_APP_TYPE;
        }

        const token = AuthHelper.getToken();
        if (token && token.trim() !== "") {
            config["headers"] = { Authorization: `Bearer ${token}` };
        }

        return new Promise((resolve, reject) => {
            if (config && config.responseType && config.responseType === "blob") {
                if (this._env) {
                    if (path.indexOf("?") > 0) {
                        path += "&_env=" + this._env;
                    } else {
                        path += "?_env=" + this._env;
                    }
                }

                axios({
                    url: config.raw ? path : this.buildApiPath(path),
                    method: method ? method : "GET",
                    responseType: "blob",
                    data: data,
                    headers: config.headers,
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            } else {
                const request =
                    method === "GET"
                        ? axios.get(config.raw ? path : this.buildApiPath(path), data, config)
                        : axios.post(config.raw ? path : this.buildApiPath(path), data, config);
                request
                    .then((response) => {
                        resolve(response.data, response);
                    })
                    .catch((error) => {
                        reject(error.response && error.response ? error.response : null);
                    });
            }
        });
    }

    buildApiPath(path) {
        path = path.trim();
        if (path.substring(0, 1) === "/") {
            path = path.substring(1);
        }
        console.log("APIURL", process.env.REACT_APP_API_URL);
        return process.env.REACT_APP_API_URL + path;
    }
}

const ApiHelper = new ApiHelperWrapper();
export { ApiHelper };
