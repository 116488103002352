class NumericHelperWrapper {
    numberFormat(number, decimals, dec_point, thousands_sep) {
        if (decimals === undefined) {
            decimals = 2;
        }

        if (dec_point === undefined) {
            dec_point = ",";
        }

        if (thousands_sep === undefined) {
            thousands_sep = ".";
        }

        number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
            dec = typeof dec_point === "undefined" ? "." : dec_point,
            s = "",
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return "" + Math.round(n * k) / k;
            };

        s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || "").length < prec) {
            s[1] = s[1] || "";
            s[1] += new Array(prec - s[1].length + 1).join("0");
        }
        return s.join(dec);
    }

    inputValueToFloat(val) {
        if (val === undefined || val === null) {
            return null;
        }

        if (typeof val == "string") {
            val = val.toString().replace(".", "").replace(".", "").replace(".", "").replace(",", ".");
        }
        return parseFloat(val);
    }

    formatBytes(a, b) {
        if (0 === a) return "0 Bytes";
        var c = 1024,
            d = b || 2,
            e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            f = Math.floor(Math.log(a) / Math.log(c));
        return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
    }
}

const NumericHelper = new NumericHelperWrapper();
export { NumericHelper };
