import { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, Content, Input, Label } from ".";
import { ApiHelper } from "../helpers";

const FilterContainer = styled.div`
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
`;

const FilterContainerElement = styled.div`
    flex: 1;
    padding: 0px 15px;
    box-sizing: border-box;
`;

const ResultsTable = styled.table`
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;

    thead tr th {
        text-align: left;
        font-weight: 600;
        border-bottom: 2px solid #eee;
        padding: 0px;
        padding-bottom: 3px;
    }

    tbody tr td {
        padding: 5px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
`;

export const ContactSelector = (props) => {
    const { onChange, label, ...rest } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [contacts, setContacts] = useState(null);
    const [filter, setFilter] = useState({
        firstname: "",
        lastname: "",
    });

    const toggle = () => {
        if (!isOpen) {
            load();
        }

        setIsOpen(!isOpen);
    };

    const onFilterChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value,
        });
    };

    const load = () => {
        setIsLoading(true);
        setContacts(null);

        ApiHelper.request("/bistro-app/contact-selector/search", filter)
            .then((response) => {
                setContacts(response.status === "success" && response.data && response.data.length > 0 ? response.data : null);
                setIsLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setContacts(null);
                setIsLoading(false);
            });
    };

    const select = (selection) => {
        if (onChange) {
            onChange({ ...selection });
        }
        setIsOpen(false);
    };

    const submit = (e) => {
        e.preventDefault();
        load();
    };

    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={toggle} ariaHideApp={false}>
                <Content>
                    <span style={{ float: "right", marginTop: -20, padding: 20, marginRight: -20 }} onClick={toggle}>
                        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer", fontSize: 20 }} />
                    </span>
                    <h2 className="mt-0">{props.label ? props.label : "Kontakt auswählen"}</h2>

                    <form onSubmit={submit}>
                        <FilterContainer>
                            <FilterContainerElement>
                                <Label>Nachname</Label>
                                <Input
                                    type="text"
                                    className="mb-2"
                                    onChange={onFilterChange}
                                    name="lastname"
                                    value={filter.lastname}
                                    disabled={isLoading}
                                />
                            </FilterContainerElement>
                            <FilterContainerElement>
                                <Label>Vorname</Label>
                                <Input
                                    type="text"
                                    className="mb-2"
                                    onChange={onFilterChange}
                                    name="firstname"
                                    value={filter.firstname}
                                    disabled={isLoading}
                                />
                            </FilterContainerElement>
                            <div>
                                <Label>&nbsp;</Label>
                                <br />
                                <Button>
                                    <FontAwesomeIcon icon={faSearch} className="mr-1" /> Suchen
                                </Button>
                            </div>
                        </FilterContainer>
                    </form>

                    <ResultsTable>
                        <thead>
                            <tr>
                                <th>Nachname</th>
                                <th>Vorname</th>
                                <th>E-Mail</th>
                                <th>Institution</th>
                                <th>Straße</th>
                                <th>Stadt</th>
                                <th style={{ textAlign: "right" }}>Optionen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan={7} style={{ textAlign: "center", userSelect: "none", paddingTop: 20 }}>
                                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                                        <div className="mt-1">Datensätze werden geladen ...</div>
                                    </td>
                                </tr>
                            ) : !contacts || contacts.length < 1 ? (
                                <tr>
                                    <td colSpan={7}>Es wurden keine Kontakte gefunden ...</td>
                                </tr>
                            ) : (
                                contacts.map((contact, i) => (
                                    <tr key={i}>
                                        <td>{contact.lastname ? contact.lastname : "-"}</td>
                                        <td>{contact.firstname ? contact.firstname : "-"}</td>
                                        <td>{contact.email ? contact.email : "-"}</td>
                                        <td>{contact.institution ? contact.institution : "-"}</td>
                                        <td>{contact.street}</td>
                                        <td>
                                            {contact.zip} {contact.city}
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            <Button type="button" onClick={() => select(contact)}>
                                                Auswählen
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </ResultsTable>
                </Content>
            </Modal>

            <Button type="button" light block onClick={toggle} {...rest}>
                {label ? label : "Kontakt auswählen"}
            </Button>
        </>
    );
};
