import { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Button, Content } from ".";
import { ApiHelper, NumericHelper } from "../helpers";

const ResultsTable = styled.table`
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;

    thead tr th {
        text-align: left;
        font-weight: 600;
        border-bottom: 2px solid #eee;
        padding: 0px;
        padding-bottom: 3px;
    }

    tbody tr td {
        padding: 5px 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
`;

const FavoriteBoxWrapper = styled.div`
    width: 25%;
    box-sizing: border-box;
`;

const FavoriteBox = styled.div`
    border: 1px solid #eee;
    border-radius: 3px;
    padding: 15px;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
    margin: 10px;
    height: 100px;
    position: relative;

    .article-title {
        font-weight: bold;
        font-size: 16px;
    }

    .article-price {
        font-size: 14px;
        position: absolute;
        bottom: 10px;
        left: 15px;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.025);
    }

    &:active {
        background: rgba(0, 0, 0, 0.05);
    }

    .reset-btn {
        position: absolute;
        right: 5px !important;
        bottom: 5px !important;
        padding: 5px;
        height: auto;
        line-height: 14px;

        &:active {
            right: 5px !important;
            bottom: 5px !important;
            position: absolute;
            top: auto;
            left: auto;
        }
    }
`;

const FavColor = styled.div`
    height: 5px;
    width: 100%;
    position: absolute;
    background: ${(props) => props.color};
    top: 0px;
    left: 0px;
`;

const FavCount = styled.div`
    position: absolute;
    background: ${(props) => props.color};
    top: -5px;
    right: -5px;
    padding: 5px 10px;
    border-radius: 10px;
    background: #02589f;
    color: #fff;
    font-weight: bold;
`;

const AddCount = styled.span`
    background: ${(props) => props.color};
    padding: 5px 10px;
    border-radius: 10px;
    background: #02589f;
    color: #fff;
    font-weight: bold;
    margin-right: 5px;
`;

const ApplyToolbar = styled.div`
    padding: 15px;
    background: #eee;
`;

export const ArticlesSelector = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [articles, setArticles] = useState(null);
    const [selection, setSelection] = useState({});

    const toggle = () => {
        if (!isOpen) {
            load();
        }

        setIsOpen(!isOpen);
    };

    const load = () => {
        setIsLoading(true);
        setArticles(null);

        ApiHelper.request("/bistro-app/articles-selector/list")
            .then((response) => {
                setArticles(
                    response.status === "success" && response.data && response.data && response.data.articles.length > 0
                        ? response.data
                        : null
                );
                setIsLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setArticles(null);
                setIsLoading(false);
            });
    };

    const reset = (e, article) => {
        e.stopPropagation();
        e.preventDefault();

        const newSelection = { ...selection };
        if (newSelection[article.id]) {
            newSelection[article.id] = undefined;
        }

        setSelection({ ...newSelection });
    };

    const minus = (article) => {
        const newSelection = { ...selection };
        if (newSelection[article.id] && newSelection[article.id].count > 0) {
            newSelection[article.id].count = newSelection[article.id].count - 1;
        }

        setSelection({ ...newSelection });
    };

    const select = (article) => {
        const newSelection = { ...selection };
        if (!newSelection[article.id]) {
            newSelection[article.id] = {
                id: article.id,
                count: 0,
                price: article.price,
                name: article.name,
                color: article.color,
                tax: article.tax,
            };
        }
        newSelection[article.id].count++;

        setSelection({ ...newSelection });
    };

    const apply = () => {
        if (props.onChange) {
            props.onChange({ ...selection });
        }
        setIsOpen(false);
        setSelection({});
    };

    const cancel = () => {
        setIsOpen(false);
        setSelection({});
    };

    let newArticlesCount = 0;
    for (let i in selection) {
        if (selection[i] && selection[i].count > 0) {
            newArticlesCount += selection[i].count;
        }
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={cancel}
                ariaHideApp={false}
                style={{ content: { padding: 0, display: "flex", flexDirection: "column", overflow: "hidden" } }}
            >
                <Content style={{ flex: 1, overflow: "auto" }}>
                    <span style={{ position: "absolute", right: 30 }} onClick={cancel}>
                        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer", fontSize: 20 }} />
                    </span>
                    <h2 className="mt-0">{props.label ? props.label : "Artikel hinzufügen"}</h2>

                    {!isLoading && articles && articles.favorites && articles.favorites.length ? (
                        <div style={{ display: "flex", flexFlow: "wrap", marginLeft: -10, marginRight: -10 }}>
                            {articles.favorites.map((fav, i) => (
                                <FavoriteBoxWrapper key={i}>
                                    <FavoriteBox onClick={(e) => select(fav)}>
                                        {fav.color && fav.color.trim() !== "" ? <FavColor color={fav.color} /> : null}
                                        {selection && selection[fav.id] && selection[fav.id].count > 0 ? (
                                            <FavCount>{selection[fav.id].count}</FavCount>
                                        ) : null}

                                        <div className="article-title">{fav.name}</div>
                                        <div className="article-price">
                                            {fav.tax
                                                ? NumericHelper.numberFormat(
                                                      parseFloat(fav.price) + parseFloat(fav.price) * parseFloat(fav.tax)
                                                  ) + " €"
                                                : parseFloat(fav.price)
                                                ? parseFloat(fav.price) + " €"
                                                : "-"}
                                        </div>

                                        {selection && selection[fav.id] && selection[fav.id].count > 0 ? (
                                            <Button onClick={(e) => reset(e, fav)} className="reset-btn" type="button" xs outline>
                                                Reset
                                            </Button>
                                        ) : null}
                                    </FavoriteBox>
                                </FavoriteBoxWrapper>
                            ))}
                            <div style={{ clear: "both" }} />
                        </div>
                    ) : null}

                    <ResultsTable>
                        <thead>
                            <tr>
                                <th>Artikelbezeichnung</th>
                                <th style={{ textAlign: "right" }}>MwSt</th>
                                <th style={{ textAlign: "right" }}>Netto</th>
                                <th style={{ textAlign: "right" }}>Brutto</th>
                                <th style={{ textAlign: "right" }}>Optionen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan={7} style={{ textAlign: "center", userSelect: "none", paddingTop: 20 }}>
                                        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                                        <div className="mt-1">Datensätze werden geladen ...</div>
                                    </td>
                                </tr>
                            ) : !articles || !articles.articles || articles.articles.length < 1 ? (
                                <tr>
                                    <td colSpan={7}>Es wurden keine Artikel gefunden ...</td>
                                </tr>
                            ) : (
                                articles.articles.map((article, i) => (
                                    <tr key={i}>
                                        <td>
                                            {selection && selection[article.id] && selection[article.id].count > 0 ? (
                                                <AddCount>{selection[article.id].count}</AddCount>
                                            ) : null}
                                            {article.name ? article.name : "-"}
                                        </td>
                                        <td style={{ textAlign: "right" }}>{article.tax ? article.tax : "-"}</td>
                                        <td style={{ textAlign: "right" }}>{article.price ? article.price : "-"}</td>
                                        <td style={{ textAlign: "right" }}>
                                            {article.price && article.tax
                                                ? NumericHelper.numberFormat(
                                                      parseFloat(article.price) + parseFloat(article.price) * parseFloat(article.tax)
                                                  )
                                                : "-"}
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            <Button type="button" className="mr-1" onClick={() => minus(article)}>
                                                <FontAwesomeIcon icon={faMinus} />
                                            </Button>
                                            <Button type="button" onClick={() => select(article)}>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </ResultsTable>
                </Content>
                <ApplyToolbar>
                    <div style={{ float: "right" }}>
                        <Button type="button" onClick={cancel} light className="mr-2">
                            Abbrechen
                        </Button>
                        <Button type="button" onClick={apply} disabled={newArticlesCount < 1}>
                            Hinzufügen
                        </Button>
                    </div>
                    <div style={{ paddingTop: 10, userSelect: "none" }}>
                        Es werden <b>{newArticlesCount}</b> neue Artikel hinzugefügt.
                    </div>
                </ApplyToolbar>
            </Modal>

            <Button type="button" primary onClick={toggle}>
                {props.label ? props.label : "Artikel auswählen"}
            </Button>
        </>
    );
};
