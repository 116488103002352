import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from ".";
import logo from "../assets/images/logo_appbar.png";
import { AuthHelper } from "../helpers/AuthHelper";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #fff;
`;

const AppBar = styled.div`
    height: 50px;
    width: 100%;
    background: #02589f;
    display: flex;

    > div > img {
        height: 40px;
        margin-top: 5px;
    }
`;

const Version = styled.div`
    color: #fff;
    opacity: 0.5;
    font-size: 12px;
    padding: 0px 10px;
`;

const UserNavigation = styled.ul`
    list-style: none;
    padding: 0px;
    margin: 0px;
    float: right;
    padding-right: 10px;

    li {
        float: left;
        padding: 0px 5px;
        line-height: 50px;
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }
`;

const Container = styled.div`
    width: 100%;
    overflow: auto;
    flex: 1;
    display: flex;
`;

export const Layout = (props) => {
    const navigate = useNavigate();
    const auth = useSelector((state) => state.app.auth);

    const logout = () => {
        if (window.confirm("Wirklich ausloggen?")) {
            AuthHelper.logout();
            navigate("/");
            window.location.reload();
        }
    };

    const refresh = () => {
        window.location.reload();
    };

    if (!auth || !auth.id || !auth.username || !auth.email) {
        return <Navigate to="/" />;
    }

    return (
        <Wrapper>
            <AppBar>
                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                    <img src={logo} alt="ZiFF-Kasse" title="ZiFF-Kasse" />

                    <Button type="button" outline sm className="ml-2" onClick={refresh}>
                        App neu laden
                    </Button>

                    <Version>v1.0.5 {process.env.REACT_APP_TYPE === "articles" ? "- ArtikelApp" : "- BistroApp"}</Version>
                </div>

                <UserNavigation>
                    <li>
                        <Button primary onClick={logout}>
                            Ausloggen
                        </Button>
                    </li>
                </UserNavigation>
            </AppBar>

            <Container>{props.children}</Container>
        </Wrapper>
    );
};
