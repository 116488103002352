import moment from "moment";
import { REDUX_SET_VALUE, REDUX_LOGOUT } from "./actions";

const authLocalStorage = localStorage.getItem("user");
const defaultAuth = authLocalStorage && authLocalStorage.trim() !== "" ? JSON.parse(authLocalStorage) : null;
const initialStore = {
    initDate: moment().format(),
    auth:
        defaultAuth && defaultAuth.email && defaultAuth.email.trim() !== "" && defaultAuth.username && defaultAuth.id ? defaultAuth : null,
};

const appReducer = (state = initialStore, action) => {
    switch (action.type) {
        case REDUX_SET_VALUE:
            return {
                ...state,
                [action.payload.setting]: action.payload.value,
            };
        case REDUX_LOGOUT:
            return {
                ...state,
                auth: null,
            };
        default:
            return state;
    }
};
export default appReducer;
