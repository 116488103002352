import styled from "styled-components";

export const Input = styled.input`
    display: block;
    width: 100%;
    height: 32px;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    box-shadow: none;
    border-radius: 0px;
    outline: none;
    box-sizing: border-box;

    &:focus {
        border-color: #66afe9;
        outline: none;
        box-shadow: none;
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
`;

export const Label = styled.label`
    font-size: 14px;
    margin-bottom: 5px;
    display: inline-block;
`;

export const Button = styled.button`
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857;
    color: #fff;
    background-color: #3276b1;
    border-color: #2c699d;
    box-shadow: inset 0 -2px 0 rgb(0 0 0 / 5%);
    border-radius: 2px;
    user-select: none;

    &:hover,
    &:focus {
        color: #fff;
        background-color: #275b89;
        border-color: #1f496d;
    }

    &:active {
        color: #fff;
        background-color: #275b89;
        background-image: none;
        border-color: #1f496d;
        position: relative;
        top: 1px;
        left: 1px;
    }

    ${(props) => (props.block ? `display: block;width: 100%;` : ``)}
    ${(props) =>
        props.success
            ? `
            color: #fff;
            background-color: #739e73;
            border-color: #659265;

            &:hover, &:focus {
                color: #fff;
                background-color: #5b835b;
                border-color: #314731;
            }

            &:active {
                color: #fff;
                background-color: #5b835b;
                border-color: #314731;
            }
    `
            : ``}

    ${(props) =>
        props.danger
            ? `
            color: #fff;
            background-color: #a90329;
            border-color: #900323;

            &:hover, &:focus {
                color: #fff;
                background-color: #77021d;
                border-color: #130005;
            }

            &:active {
                color: #fff;
                background-color: #540114;
                border-color: #130005;
            }
    `
            : ``}

    ${(props) =>
        props.light
            ? `
            color: #555;
            background-color: #ededed;
            border-color: #d9d9d9;

            &:hover, &:focus {
                color: #555;
                background-color: #dfdfdf;
                border-color: #d9d9d9;
            }

            &:active {
                color: #555;
                background-color: #dfdfdf;
                border-color: #d9d9d9;
            }
    `
            : ``}

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
`;

export const ButtonLinkExternal = styled.a`
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857;
    color: #fff;
    background-color: #3276b1;
    border-color: #2c699d;
    box-shadow: inset 0 -2px 0 rgb(0 0 0 / 5%);
    border-radius: 2px;
    user-select: none;
    text-decoration: none;

    &:hover,
    &:focus {
        color: #fff;
        background-color: #275b89;
        border-color: #1f496d;
    }

    &:active {
        color: #fff;
        background-color: #275b89;
        background-image: none;
        border-color: #1f496d;
        position: relative;
        top: 1px;
        left: 1px;
    }

    ${(props) => (props.block ? `display: block;width: 100%;` : ``)}
    ${(props) =>
        props.success
            ? `
            color: #fff;
            background-color: #739e73;
            border-color: #659265;

            &:hover, &:focus {
                color: #fff;
                background-color: #5b835b;
                border-color: #314731;
            }

            &:active {
                color: #fff;
                background-color: #5b835b;
                border-color: #314731;
            }
    `
            : ``}

    ${(props) =>
        props.danger
            ? `
            color: #fff;
            background-color: #a90329;
            border-color: #900323;

            &:hover, &:focus {
                color: #fff;
                background-color: #77021d;
                border-color: #130005;
            }

            &:active {
                color: #fff;
                background-color: #540114;
                border-color: #130005;
            }
    `
            : ``}

    ${(props) =>
        props.light
            ? `
            color: #555;
            background-color: #ededed;
            border-color: #d9d9d9;

            &:hover, &:focus {
                color: #555;
                background-color: #dfdfdf;
                border-color: #d9d9d9;
            }

            &:active {
                color: #555;
                background-color: #dfdfdf;
                border-color: #d9d9d9;
            }
    `
            : ``}

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
`;
